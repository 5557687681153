import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("video", {
    id: "video",
    src: $data.videoPath,
    controls: "",
    style: {
      "width": "100%",
      "height": "auto"
    }
  }, null, 8, _hoisted_2)]);
}