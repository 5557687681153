import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.detached.js";
import "core-js/modules/es.array-buffer.transfer.js";
import "core-js/modules/es.array-buffer.transfer-to-fixed-length.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
// @ is an alias to /src
import BNav from '@/components/BottomNav.vue';
import { compress } from '@/common/imageUtil';
import ObsClient from 'esdk-obs-browserjs/src/obs';
import { v4 as uuidv4 } from "uuid";
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg'
// import { createFFmpeg, fetchFile } from '@/common/ffmpeg.min.js'
// const { createFFmpeg, fetchFile } = FFmpeg
import op1 from '@/assets/manage/op1.png';
import op2 from '@/assets/manage/op2.png';
import op3 from '@/assets/manage/op3.png';
import op4 from '@/assets/manage/op4.png';
import op5 from '@/assets/manage/op5.png';
import op6 from '@/assets/manage/op6.png';
import op7 from '@/assets/manage/op7.png';
import op8 from '@/assets/manage/op8.png';
import op9 from '@/assets/manage/op9.png';
import op10 from '@/assets/manage/op10.png';
import op11 from '@/assets/manage/op11.png';
import op12 from '@/assets/manage/op12.png';
import { Delete, Edit, Upload } from '@element-plus/icons-vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  setup() {
    return {
      Delete,
      Edit,
      Upload
    };
  },
  name: 'ManageView',
  components: {
    BNav
  },
  data() {
    return {
      folder_update: true,
      folders: [],
      interval: 3000,
      addVisible: false,
      editVisible: false,
      deleteVisible: false,
      add_folder_name: "",
      edit_folder_name: "",
      dialogVisible_up_image: false,
      fileList: [],
      share: false,
      editUsernameVisible: false,
      edit_username: "",
      video_msg: "",
      video_file: "",
      video_first_img_file: "",
      dialogVisible_up_video: false,
      dialogVisible_up_text2img: false,
      dialogVisible_up_zs: false,
      up_video_flag: false,
      up_video_first_img_flag: false,
      mycanvas: "",
      videoOssPath: "",
      videoFirstImgOssPath: "",
      selNum: "",
      question: "",
      dialogVisible_up_say: false,
      textarea1: "",
      sudokus: [{
        img_src: op1,
        name: '添加目录',
        id: 0
      }, {
        img_src: op2,
        name: '改昵称',
        id: 1
      }, {
        img_src: op3,
        name: '上传视频',
        id: 2
      }, {
        img_src: op4,
        name: '占占',
        id: 3
      }, {
        img_src: op5,
        name: '写文档',
        id: 4
      }, {
        img_src: op6,
        name: '文档列表',
        id: 5
      }, {
        img_src: op7,
        name: '记一笔',
        id: 6
      }, {
        img_src: op8,
        name: 'account列表',
        id: 7
      }, {
        img_src: op9,
        name: '打卡',
        id: 8
      }, {
        img_src: op10,
        name: 'OCR',
        id: 9
      }, {
        img_src: op11,
        name: 'SAY',
        id: 10
      }, {
        img_src: op12,
        name: 'SAY列表',
        id: 11
      }]
    };
  },
  methods: {
    selectFolderList() {
      let _this = this;
      this.$axios.post('/photo/select_folder_page', JSON.stringify({
        page: 1,
        pageSize: 100
      })).then(function (response) {
        console.log(response.data);
        console.log(response.data.dataList);
        _this.folders = response.data.dataList;
      }).catch(function (error) {
        console.log(error);
      });
    },
    saveFolder() {
      // 点击添加目录按钮
      this.addVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/add_folder',
        data: JSON.stringify({
          title: _this.add_folder_name,
          pid: "0"
        }),
        method: "POST"
      }).then(function (response) {
        _this.add_folder_name = "";
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          _this.selectFolderList();
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    editFolder(id, title) {
      console.log("editFolder");
      this.edit_folder_name = title;
      this.edit_folder_id = id;
      this.editVisible = true;
    },
    saveEdit() {
      // 点击编辑目录按钮
      this.editVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/update_folder',
        data: JSON.stringify({
          title: _this.edit_folder_name,
          id: _this.edit_folder_id
        }),
        method: "POST"
      }).then(function (response) {
        _this.edit_folder_name = "";
        _this.edit_folder_id = null;
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          _this.selectFolderList();
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    deleteFolder(id) {
      this.delete_folder_id = id;
      this.deleteVisible = true;
    },
    saveDelete() {
      // 点击编辑目录按钮
      this.deleteVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/del_folder',
        data: JSON.stringify({
          id: _this.delete_folder_id
        }),
        method: "POST"
      }).then(function (response) {
        _this.delete_folder_id = null;
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          _this.selectFolderList();
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
        ElMessage(response.data.error);
      });
    },
    openUploadImage(id) {
      console.log("openUploadImage");
      this.dialogVisible_up_image = true;
      this.upload_folder_id = id;
    },
    // 判断图片格式 和 文件大小
    beforeAvatarUploadImage(file) {
      // 该方法不起作用
      console.log("beforeAvatarUploadImage:", isJpeg);
      // || file.type == 'video/mp4'
      const isJpeg = file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpeg) {
        this.$message.error('请选择正确的文件格式上传');
      }
      return isJpeg;
    },
    // 选择文件时，往fileList里添加
    handleChange(file) {
      console.log(file.raw.type);
      const isJpeg = file.raw.type === 'image/png' || file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg';
      if (!isJpeg) {
        this.$message.error('jpg/png以外的文件将不会被上传');
      } else {
        this.fileList.push(file);
        // 判断如果大于2M，则压缩
        const isLt2M = file.size > 1024 * 1024 * 2;
        if (isLt2M) {
          const that = this;
          // 回显
          // this.dialogImageUrl = URL.createObjectURL(file.raw)
          // 调用自定义的压缩方法
          compress(file.raw, function (val) {
            // 图片格式： blob => file
            console.log("压缩前:", file.raw);
            let newFile = new window.File([val], file.name, {
              type: file.raw.type
            });
            newFile.uid = file.uid;
            file.raw = newFile;
            // 新增属性（file）并赋值 （that.uploadParams是俺自定义传给后端的值，根据自己项目情况赋值）
            // that.$set(that.uploadParams, 'file', newFile)
            // // 查看压缩后的图片路径
            console.log("压缩后:", newFile);
            console.log('newFileURL', URL.createObjectURL(val));
          });
        }
      }
      ElMessage("图片已准备就续：" + this.fileList.length);
    },
    handleRemove(file) {
      this.fileList.pop(file);
    },
    // 批量上传
    uploadForm() {
      // this.$refs.upload.submit()
      let _this = this;
      if (this.fileList.length === 0) {
        this.$message.warning('请选取文件');
        return;
      }
      this.dialogVisible_up_image = false;
      const formData = new FormData();
      // 因为要传一个文件数组过去，所以要循环append
      this.fileList.forEach(file => {
        formData.append('files', file.raw);
      });
      formData.append("folder_id", this.upload_folder_id);
      console.log("share", this.share);
      formData.append("share", 'yes');
      this.$axios.post('/photo/upload_img_files', formData).then(res => {
        _this.$message.success('图片上传成功！');
        setTimeout(() => {
          _this.dialogVisible_up_image = false;
        }, 200);
      }).catch(res => {
        _this.$message.success('图片上传失败！');
        setTimeout(() => {
          _this.dialogVisible_up_image = false;
        }, 200);
      });
      // 清空图片列表（一定要清空，否则上传成功后还是会调用handleChange（）函数，上传成功后列表中还存在图片）
      this.fileList = [];
      this.share = false;
    },
    // 提交Say,判断有无图片
    commitSay() {
      // this.$refs.upload.submit()
      let image_path = "";
      let _this = this;
      if (this.fileList.length !== 0) {
        this.dialogVisible_up_say = false;
        const formData = new FormData();
        // 因为要传一个文件数组过去，所以要循环append
        this.fileList.forEach(file => {
          formData.append('myfile', file.raw);
        });
        this.$axios.post('/photo/upload_public_img', formData).then(res => {
          if (res.data.data.oss_path) {
            image_path = res.data.data.oss_path;
          }
          console.log("图片地址：", image_path);
          _this.commitSayText(_this, image_path);
        }).catch(function (error) {
          console.log(error);
          _this.dialogVisible_up_say = false;
        });
        // 清空图片列表（一定要清空，否则上传成功后还是会调用handleChange（）函数，上传成功后列表中还存在图片）
        this.fileList = [];
        this.share = false;
      } else {
        this.commitSayText(this, image_path);
      }
    },
    commitSayText(_this, image_path) {
      _this.$axios.post('/photo/say/add', JSON.stringify({
        content: _this.textarea1,
        image_path: image_path
      })).then(function (response) {
        console.log(response.data);
        console.log(response.data.dataList);
        _this.dialogVisible_up_say = false;
        _this.textarea1 = "";
      }).catch(function (error) {
        console.log(error);
        _this.dialogVisible_up_say = false;
        _this.textarea1 = "";
      });
    },
    saveUsernameEdit() {
      if (this.edit_username.trim() == "") {
        ElMessage("昵称不能为空");
        return;
      }
      if (this.edit_username.trim() == localStorage.getItem('username')) {
        ElMessage("与原昵称一样，无需修改");
        return;
      }
      this.editUsernameVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/change_username',
        data: JSON.stringify({
          new_username: _this.edit_username
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          _this.edit_username = "";
          // 替换token
          localStorage.setItem('userToken', response.data.data.token);
          localStorage.setItem('username', response.data.data.username);
          _this.$store.commit('setToken', response.data.data.token);
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
        ElMessage(response.data.error);
      });
    },
    async uploadVideo(e) {
      let _this = this;
      this.up_video_flag = false;
      this.up_video_first_img_flag = false;
      // const ffmpeg = createFFmpeg({
      //   corePath: "ffmpeg-core.js",
      //   log: true,
      //   progress: ({ ratio }) => {
      //     _this.video_msg = `完成率: ${(ratio * 100.0).toFixed(2)}%`;
      //   },
      // });
      // let { name } = e.target.files[0];

      // this.video_msg = '正在加载 ffmpeg-core.js'
      // await ffmpeg.load();
      // this.video_msg = "开始压缩"
      // ffmpeg.FS('writeFile', name, await fetchFile(e.target.files[0]));
      // await ffmpeg.run('-i', name, '-b', '2000000', 'put.mp4');
      // this.video_msg = '压缩完成'
      // const data = ffmpeg.FS('readFile', 'put.mp4');
      const video = document.getElementById('video');
      // let newFile = new Blob([data.buffer], { type: 'video/mp4' })
      // this.video_file = new window.File([newFile], name, { type: 'video/mp4' });
      // console.log(this.video_file)
      // 得到视频文件
      this.video_file = e.target.files[0];
      video.src = URL.createObjectURL(this.video_file);
    },
    text2img() {
      // 打开文生图对话框
      this.dialogVisible_up_text2img = true;
    },
    uploadVideoForm() {
      console.log(this.video_file);
      if (video.src == '') {
        ElMessage("请先选择视频文件");
        return;
      }
      ElMessage("正在上传视频,请稍等……");
      let _this = this;
      // const formData = new FormData()
      // formData.append('file', this.video_file)

      // 下面是先传后端再由后端上传oss改为前端直接传oss
      // this.$axios({
      //   url: '/photo/upload_video_file',
      //   data: formData,
      //   method: "POST",
      // }).then(function (response) {
      //   console.log(response.data)
      //   if (response.data.code == 200) {
      //     ElMessage(response.data.message)
      //     _this.video_file = ""
      //     _this.dialogVisible_up_video = false
      //   } else {
      //     ElMessage(response.data.message)
      //   }
      // })
      //   .catch(function (error) {
      //     console.log(error);
      //     ElMessage(response.data.error)
      //   });

      // 得到视频第一帧图片
      this.mycanvas = document.getElementById('mycanvas');
      const video1 = document.getElementById('video');
      const canvas = this.mycanvas;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video1, 0, 0, video1.videoWidth, video1.videoHeight);
      const oGrayImg = canvas.toDataURL('image/jpeg');
      const arr = oGrayImg.split(',');
      // 获取图片的类型
      const type = arr[0].match(/:(.*?);/)[1];
      // 解码
      const bstr = window.atob(arr[1]);
      // 获取解码后的字符长度
      let bstrLength = bstr.length;
      // 新建一个无符号整型数组。创建时内容被初始化为0
      const u8arr = new Uint8Array(bstrLength);
      while (bstrLength--) {
        // 给u8arr里面指定位置的字符的 Unicode 编码
        u8arr[bstrLength] = bstr.charCodeAt(bstrLength);
      }
      this.video_first_img_file = new File([u8arr], 'videoimg.jpg', {
        type
      });
      // window.URL.createObjectURL(file) file文件转成blob地址
      // this.imgUrl = window.URL.createObjectURL(video_first_img_file)

      let callback1 = function (transferredAmount, totalAmount, totalSeconds) {
        // 获取上传平均速率（KB/S）
        _this.video_msg = "完成率: " + Math.floor(transferredAmount * 100.0 / totalAmount) + "%";
        console.log(transferredAmount * 1.0 / totalSeconds / 1024);
        // 获取上传进度百分比
        console.log(transferredAmount * 100.0 / totalAmount);
        // 百分比取整数
        console.log(Math.floor(transferredAmount * 100.0 / totalAmount));
      };
      // 生成随机oss文件地址
      const data1 = new Date().toISOString();
      let uuid = uuidv4();
      this.videoOssPath = "video/" + data1.substring(0, 7) + "/" + uuid + ".mp4";
      this.videoFirstImgOssPath = "video/" + data1.substring(0, 7) + "/" + uuid + ".jpg";
      this.obsClient.putObject({
        Bucket: 'w-many',
        Key: this.videoFirstImgOssPath,
        SourceFile: this.video_first_img_file,
        ProgressCallback: callback1
      }, function (err, result) {
        if (err) {
          console.error('Error-->' + err);
        } else {
          console.log('Status-->' + result.CommonMsg.Status);
          if (result.CommonMsg.Status == 200) {
            _this.up_video_first_img_flag = true;
          }
          console.log("上传第一帧图片成功");
        }
      });
      this.obsClient.putObject({
        Bucket: 'w-many',
        Key: this.videoOssPath,
        SourceFile: this.video_file,
        ProgressCallback: callback1
      }, function (err, result) {
        if (err) {
          console.error('Error-->' + err);
        } else {
          console.log('Status-->' + result.CommonMsg.Status);
          if (result.CommonMsg.Status == 200) {
            _this.up_video_flag = true;
          }
          console.log("上传video成功");
        }
      });
    },
    // createText2img() {
    //   let _this = this
    //   this.$axios({
    //     url: '/photo/text2img',
    //     data: JSON.stringify({
    //       text: this.edit_text_prompt,
    //       style: this.style_value,
    //       resolution: this.resolution_value,
    //       num: 1,
    //     }),
    //     method: "POST",
    //   }).then(function (response) {
    //     console.log(response.data)
    //     if (response.data.code == 200) {
    //       ElMessage(response.data.message)
    //       _this.dialogVisible_up_text2img = false
    //     } else {
    //       ElMessage(response.data.message)
    //     }
    //   })
    //     .catch(function (error) {
    //       console.log(error);
    //       ElMessage(response.data.error)
    //     });
    // },

    goQueryZs() {
      if (this.selNum == "" || this.selNum.length != 9) {
        ElMessage("请输入9位数字");
      } else {
        // 计算数字瓜
        let b = this.selNum.substring(0, 3);
        let s = this.selNum.substring(3, 6);
        let g = this.selNum.substring(6, 9);
        b = b % 8;
        if (b == 0) b = 8;
        s = s % 8;
        if (s == 0) s = 8;
        g = g % 6;
        if (g == 0) g = 6;
        let targetSelNum = b * 100 + s * 10 + g;
        let _this = this;
        this.$axios({
          url: '/photo/query_zs',
          data: JSON.stringify({
            selNum: targetSelNum + "",
            nineCode: this.selNum + "",
            question: this.question + ""
          }),
          method: "POST"
        }).then(function (response) {
          console.log(response.data);
          if (response.data.code == 200) {
            _this.dialogVisible_up_zs = false;
            localStorage.setItem('xlzsContent', response.data.data.content);
            localStorage.setItem('xlzxVideoPath', response.data.data.video_path);
            _this.$router.push({
              name: 'xlzsDetail'
            });
          } else {
            ElMessage(response.data.message);
          }
        });
        this.selNum = "";
        this.question = "";
      }
    },
    start(e) {
      var that = this;
      var list = that.sudokus;
      that.curSelect = e;
      if (e == 0) {
        // 点击添加目录按钮
        this.addVisible = true;
      } else if (e == 1) {
        // 点击“改昵称”按钮
        this.editUsernameVisible = true;
        let _this = this;
        this.$axios({
          url: '/photo/get_user_info',
          method: "POST"
        }).then(function (response) {
          console.log(response.data);
          if (response.data.code == 200) {
            _this.edit_username = response.data.data.username;
          }
        });
      } else if (e == 2) {
        // 打开上传视频对话框
        this.dialogVisible_up_video = true;
      } else if (e == 3) {
        // 打开占占对话框
        this.dialogVisible_up_zs = true;
      } else if (e == 4) {
        // 写文档
        let _this = this;
        if (localStorage.getItem("doc_id") != null && localStorage.getItem("doc_content") != null) {
          this.$axios({
            url: '/photo/doc/add',
            data: JSON.stringify({
              content: localStorage.getItem("doc_content"),
              doc_id: localStorage.getItem("doc_id")
            }),
            method: "POST"
          }).then(res => {
            localStorage.removeItem("doc_id");
            localStorage.removeItem("doc_content");
            console.log("保存暂存的文档成功");
            _this.$router.push({
              name: 'edit'
            });
          });
        } else {
          _this.$router.push({
            name: 'edit'
          });
        }
      } else if (e == 5) {
        // 文档列表
        this.$router.push({
          name: 'editList'
        });
      } else if (e == 6) {
        // 记一笔
        this.$router.push({
          name: 'account'
        });
      } else if (e == 7) {
        // account列表
        this.$router.push({
          name: 'accountList'
        });
      } else if (e == 8) {
        // 打卡
        this.$router.push({
          name: 'clockSet'
        });
      } else if (e == 9) {
        // OCR
        this.$router.push({
          name: 'doOcr'
        });
      } else if (e == 10) {
        // 打开Say对话框
        this.dialogVisible_up_say = true;
      } else if (e == 11) {
        // 跳到sayList
        this.$router.push({
          name: 'sayList'
        });
      }
    }
  },
  mounted() {
    this.selectFolderList();
  },
  created() {
    this.obsClient = new ObsClient({
      access_key_id: 'SBAXYJSXJNSJ7L0S7UAL',
      secret_access_key: 'meCu3IOyl6Bv0yxwgsIT4Y0Saua7heFLyM6I7UQV',
      server: 'obs.cn-east-3.myhuaweicloud.com'
    });
  },
  watch: {
    up_video_flag(newValue, oldValue) {
      if (newValue == true) {
        // 如果视频和第一帧都上传好了，就开始保存数据库
        let _this = this;
        let video2 = document.getElementById("video");
        let uploadVideo = document.getElementById("uploadVideo");
        this.$axios({
          url: '/photo/save_video_info',
          data: JSON.stringify({
            filename: this.video_file.name,
            path: this.videoOssPath,
            prev_paths: this.videoFirstImgOssPath,
            ext: "mp4",
            file_size: this.video_file.size,
            folder_id: 1,
            remark: "",
            share: "yes"
          }),
          method: "POST"
        }).then(function (response) {
          console.log(response.data);
          if (response.data.code == 200) {
            ElMessage(response.data.message);
            _this.video_file = "";
            _this.dialogVisible_up_video = false;
            _this.up_video_flag = false;
            _this.up_video_first_img_flag = false;
            video2.src = "";
            uploadVideo.value = "";
          } else {
            ElMessage(response.data.message);
          }
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  }
};