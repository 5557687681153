import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios';
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// 增加的引入
import 'element-plus/dist/index.css'

const app = createApp(App)
app.provide('$axios', axios)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.use(VueAxios, axios).use(store).use(router).use(ElementPlus).mount('#app')

// axios.defaults.baseURL = '/api'  // 本地启动配置
// axios.defaults.baseURL = 'http://po.wtre.top/'  // 打包APP时需配置
axios.defaults.baseURL = 'http://photo.wtre.top/api/'  // 打包服务器nginx时需配置

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// http request 拦截器
axios.interceptors.request.use(
    config => {
        const token = store.state.token;
        if (token) {  // 判断是否存在 token, 如果存在的话, 则每个 http header 都加上 token
            config.headers.Authorization = token;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                case 403:
                    // 返回 401 或403 清除 token 信息并跳转到登录页面
                    store.commit("clearToken");
                    router.replace({
                        path: 'login',
                        query: { redirect: router.currentRoute.fullPath }
                    })
            }
        }
        return Promise.reject(error.response.data)   // 返回接口返回的错误信息
    });

//将axios挂载到原型对象上，vue3相比vue2有所改变，vue2这样写：Vue.prototype.$http = axios
app.config.globalProperties.$axios = axios;

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
        if (store.state.token) {  // 通过 vuex state 获取当前的 token 是否存在
            next();
        }
        else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }  // 将跳转的路由 path 作为参数, 登录成功后跳转到该路由
            })
        }
    }
    else {
        next();
    }
})


// 定义全局属性
app.config.globalProperties.$roll_app_id = 'sd9hcftpqkciosmp';
app.config.globalProperties.$roll_app_secret = 'TgDdUf6NfWGJfOqibk2Am8Cqo52L4cEX';
app.config.globalProperties.$loading1 = "./assets/manage/dogLoading.gif"



