import BNav from '@/components/BottomNav.vue';
import chengyu from '@/assets/tools/icon_api_chengyu.jpg';
import dict from '@/assets/tools/icon_api_dict.jpg';
import haha from '@/assets/tools/icon_api_haha.jpg';
import ip from '@/assets/tools/icon_api_ip.jpg';
import one from '@/assets/tools/icon_api_one.jpg';
import xiehouyu from '@/assets/tools/icon_api_xiehouyu.png';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'ToolsView',
  components: {
    BNav
  },
  data() {
    return {
      sudokus: [{
        img_src: chengyu,
        name: '成语大全',
        id: 0
      }, {
        img_src: dict,
        name: '汉语字典',
        id: 1
      }, {
        img_src: haha,
        name: '每日一笑',
        id: 2
      }, {
        img_src: ip,
        name: 'IP解析',
        id: 3
      }, {
        img_src: one,
        name: '每日一句',
        id: 4
      }, {
        img_src: xiehouyu,
        name: '歇后语大全',
        id: 5
      }],
      curSelect: null,
      dialogVisible_up_chengyu: false,
      chengyu_content: "",
      chengyu_content_result: "",
      dialogVisible_up_dict: false,
      dict_content: "",
      dict_content_result: "",
      dialogVisible_up_joke: false,
      joke_content_result: "",
      dialogVisible_up_ip: false,
      ip_content: "",
      ip_content_result: "",
      dialogVisible_up_sentence: false,
      sentence_content_result: "",
      dialogVisible_up_xiehouyu: false,
      xiehouyu_content: "",
      xiehouyu_content_result: ""
    };
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    start: function (e) {
      var that = this;
      var list = that.sudokus;
      that.curSelect = e;
      if (e == 0) {
        that.chengyu_content = "";
        that.chengyu_content_result = "";
        that.dialogVisible_up_chengyu = true;
      } else if (e == 1) {
        that.dict_content = "";
        that.dict_content_result = "";
        that.dialogVisible_up_dict = true;
      } else if (e == 2) {
        that.joke_content_result = "";
        that.dialogVisible_up_joke = true;
      } else if (e == 3) {
        that.ip_content = "";
        that.ip_content_result = "";
        that.dialogVisible_up_ip = true;
      } else if (e == 4) {
        that.sentence_content_result = "";
        that.dialogVisible_up_sentence = true;
      } else if (e == 5) {
        that.xiehouyu_content = "";
        that.xiehouyu_content_result = "";
        that.dialogVisible_up_xiehouyu = true;
      }
    },
    goQueryChengyu(key) {
      let that = this;
      console.log("要查询的成语：", key);
      // https://www.mxnzp.com/api/idiom/search?key=一心一意&app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: 'https://www.mxnzp.com/api/idiom/search?key=' + key + "&app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data[0];
          that.chengyu_content_result = "【拼音】：" + the_data['pinyin'] + "\n\n" + "【解释】：" + the_data['explanation'] + "\n\n" + "【由来】：" + the_data['derivation'] + "\n\n" + "【示例】：" + the_data['example'];
          console.log("that.chengyu_content_result:", that.chengyu_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQueryDict(key) {
      let that = this;
      console.log("要查询的汉字：", key);
      // https://www.mxnzp.com/api/convert/dictionary?content=穆&app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: 'https://www.mxnzp.com/api/convert/dictionary?content=' + key + "&app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data[0];
          that.dict_content_result = "【拼音】：" + the_data['pinyin'] + "\n\n" + "【偏旁】：" + the_data['radicals'] + "\n\n" + "【解释】：" + the_data['explanation'];
          console.log("that.dict_content_result:", that.dict_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQueryJoke() {
      this.joke_content_result = "";
      let that = this;
      this.$axios({
        url: "https://www.mxnzp.com/api/jokes/list/random?app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let data_list = response.data.data;
          for (let i = 0; i < data_list.length; i++) {
            that.joke_content_result = that.joke_content_result + (i + 1) + "：" + data_list[i]['content'] + "\n\n";
          }
          console.log("that.joke_content_result:", that.joke_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQuerySelfIp() {
      this.ip_content_result = "";
      this.ip_content = "";
      let that = this;
      // https://www.mxnzp.com/api/ip/self?app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: "https://www.mxnzp.com/api/ip/self?app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data;
          that.ip_content_result = "【IP】：" + the_data['ip'] + "\n\n" + "【省份】：" + the_data['province'] + "\n\n" + "【省份编码】：" + the_data['provinceId'] + "\n\n" + "【城市】：" + the_data['city'] + "\n\n" + "【城市编码】：" + the_data['cityId'] + "\n\n" + "【供应商】：" + the_data['isp'] + "\n\n" + "【描述】：" + the_data['desc'];
          console.log("that.ip_content_result:", that.ip_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQueryIp() {
      this.ip_content_result = "";
      if (this.ip_content == "") {
        ElMessage("请输入要查询的IP");
        return;
      }
      let that = this;
      // https://www.mxnzp.com/api/ip/aim_ip?ip=119.123.72.166&app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: "https://www.mxnzp.com/api/ip/aim_ip?ip=" + that.ip_content + "&app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data;
          that.ip_content_result = "【IP】：" + the_data['ip'] + "\n\n" + "【省份】：" + the_data['province'] + "\n\n" + "【省份编码】：" + the_data['provinceId'] + "\n\n" + "【城市】：" + the_data['city'] + "\n\n" + "【城市编码】：" + the_data['cityId'] + "\n\n" + "【供应商】：" + the_data['isp'] + "\n\n" + "【描述】：" + the_data['desc'];
          console.log("that.ip_content_result:", that.ip_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQuerySentence() {
      this.sentence_content_result = "";
      let that = this;
      // https://www.mxnzp.com/api/daily_word/recommend?count=10&app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: "https://www.mxnzp.com/api/daily_word/recommend?count=1&app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data[0];
          that.sentence_content_result = the_data['content'] + "\n\n" + "【作者】：" + the_data['author'];
          console.log("that.sentence_content_result:", that.sentence_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQueryRandomXiehouyu() {
      this.xiehouyu_content_result = "";
      this.xiehouyu_content = "";
      let that = this;
      // https://www.mxnzp.com/api/xiehouyu/random?app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: "https://www.mxnzp.com/api/xiehouyu/random?app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data;
          that.xiehouyu_content_result = "【谜题】：" + the_data['riddle'] + "\n\n" + "【谜底】：" + the_data['answer'];
          console.log("that.xiehouyu_content_result:", that.xiehouyu_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    },
    goQueryXiehouyu() {
      this.xiehouyu_content_result = "";
      if (this.xiehouyu_content == "") {
        ElMessage("请输入要查询的谜题");
        return;
      }
      let that = this;
      // https://www.mxnzp.com/api/xiehouyu/search?key=小葱拌豆腐&app_id=不再提供请自主申请&app_secret=不再提供请自主申请
      this.$axios({
        url: "https://www.mxnzp.com/api/xiehouyu/search?key=" + that.xiehouyu_content + "&app_id=" + this.$roll_app_id + "&app_secret=" + this.$roll_app_secret,
        method: "GET"
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 1) {
          let the_data = response.data.data;
          that.xiehouyu_content_result = "【谜题】：" + the_data['riddle'] + "\n\n" + "【谜底】：" + the_data['answer'];
          console.log("that.xiehouyu_content_result:", that.xiehouyu_content_result);
        } else {
          ElMessage(response.data.msg.substring(0, 18));
        }
      });
    }
  }
};