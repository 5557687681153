import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-db8c08ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "custom-loading"
};
const _hoisted_2 = {
  class: "img-box"
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $props.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    src: $props.imageUrl
  }, null, 8, _hoisted_3)])])) : _createCommentVNode("", true);
}