import "core-js/modules/es.array.push.js";
import md5 from "js-md5";
import { ElMessage } from 'element-plus';
export default {
  name: 'LoginView',
  data() {
    return {
      phoneNumber: '',
      pwd: '',
      errorMessage: '',
      isLoggingIn: false
    };
  },
  beforeCreate() {},
  methods: {
    handleSubmit() {
      let _this = this;
      _this.isLoggingIn = true;
      this.$axios({
        url: '/photo/login',
        data: JSON.stringify({
          username: _this.phoneNumber,
          password: md5(_this.pwd)
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          localStorage.setItem('userToken', response.data.token);
          localStorage.setItem('username', response.data.username);
          _this.$router.push('/');
          _this.$store.commit('setToken', response.data.token);
        } else {
          _this.isLoggingIn = false;
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
        _this.isLoggingIn = false;
      });
    }
  },
  mounted() {}
};