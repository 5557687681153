import { createStore } from 'vuex'

export default createStore({
  state: {
    // 存储token
    token: localStorage.getItem('userToken') ? localStorage.getItem('userToken') : ''
  },
  getters: {
  },
  mutations: {
    setToken(state, val) {
      state.token = val
    },
    clearToken(state) {
      state.token = ''
    }
  },
  actions: {
  },
  modules: {
  }
})
