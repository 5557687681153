import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { Delete, Edit, Share, Upload, Download } from '@element-plus/icons-vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  data() {
    return {
      category_id: null,
      money: null,
      options: [{
        value: '',
        label: ''
      }],
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      textarea: "",
      prev_paths: ""
    };
  },
  mounted() {
    let _this = this;
    _this.$axios({
      url: '/photo/attr/list',
      data: JSON.stringify({
        category: 'account'
      }),
      method: "POST"
    }).then(function (res) {
      console.log("查询记账分类成功", res);
      _this.options = [];
      res.data.dataList.forEach(element => {
        _this.options.push({
          'value': element['id'],
          'label': element['name']
        });
      });
    }).catch(function (error) {
      console.log(error);
      ElMessage(response.data.error);
    });
  },
  methods: {
    purposeChange() {
      console.log("purpose:", this.purpose);
    },
    // 判断图片格式 和 文件大小
    beforeAvatarUploadImage(file) {
      // 该方法不起作用
      console.log("beforeAvatarUploadImage:", isJpeg);
      // || file.type == 'video/mp4'
      const isJpeg = file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpeg) {
        this.$message.error('请选择正确的文件格式上传');
      }
      return isJpeg;
    },
    // 选择文件时，往fileList里添加
    handleChange(file) {
      console.log(file.raw.type);
      const isJpeg = file.raw.type === 'image/png' || file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg';
      if (!isJpeg) {
        this.$message.error('jpg/png以外的文件将不会被上传');
      } else {
        this.fileList.push(file);
        // 判断如果大于2M，则压缩
        const isLt2M = file.size > 1024 * 1024 * 2;
        if (isLt2M) {
          const that = this;
          // 回显
          // this.dialogImageUrl = URL.createObjectURL(file.raw)
          // 调用自定义的压缩方法
          compress(file.raw, function (val) {
            // 图片格式： blob => file
            console.log("压缩前:", file.raw);
            let newFile = new window.File([val], file.name, {
              type: file.raw.type
            });
            newFile.uid = file.uid;
            file.raw = newFile;
            // 新增属性（file）并赋值 （that.uploadParams是俺自定义传给后端的值，根据自己项目情况赋值）
            // that.$set(that.uploadParams, 'file', newFile)
            // // 查看压缩后的图片路径
            console.log("压缩后:", newFile);
            console.log('newFileURL', URL.createObjectURL(val));
          });
        }
      }
    },
    handleRemove(file) {
      this.fileList.pop(file);
    },
    saveAccount() {
      console.log("保存记账...");
      console.log("textarea:", this.textarea);
      console.log("money:", this.money);
      console.log("category_id:", this.category_id);
      console.log("fileList:", this.fileList);
      console.log("fileList:", this.fileList.length);
      if (this.money == null || this.money == 0) {
        ElMessage("请核对金额：", this.money);
        return;
      }
      if (this.category_id == null || this.category_id == '') {
        ElMessage("请核对用途：", this.category_id);
        return;
      }
      // 请求上传图片，成功之后，继续请求保存数据

      const formData = new FormData();
      // 因为要传一个文件数组过去，所以要循环append
      if (this.fileList.length === 0) {
        formData.append('files', []);
      } else {
        this.fileList.forEach(file => {
          formData.append('files', file.raw);
        });
      }
      let _this = this;
      this.$axios.post('/photo/account/upload_images', formData).then(res => {
        console.log("记账前上传图片结果：", res);
        if (res.data.dataList.length > 0) {
          _this.prev_paths = res.data.dataList.join(",");
        }
        _this.$axios({
          url: '/photo/account/add',
          data: JSON.stringify({
            money: _this.money,
            category_id: _this.category_id,
            remark: _this.textarea,
            prev_paths: _this.prev_paths
          }),
          method: "POST"
        }).then(function (response) {
          console.log("保存记账结果：", response.data);
          if (response.data.code == 200) {
            _this.fileList = [];
            setTimeout(() => {
              ElMessage(response.data.message);
            }, 200);
            _this.$router.push('/folder_manage');
          } else {
            ElMessage(response.data.message);
          }
        }).catch(function (error) {
          console.log(error);
          ElMessage(response.data.error);
        });
      }).catch(res => {
        this.$message.success('图片保存失败！');
      });
      // 清空图片列表（一定要清空，否则上传成功后还是会调用handleChange（）函数，上传成功后列表中还存在图片）
    }
  }
};