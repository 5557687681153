import "core-js/modules/es.array.push.js";
import CustomLoading from '@/components/my/CustomLoading.vue';
import loading1 from '@/assets/manage/dogLoading.gif';
import loading2 from '@/assets/manage/waterLoading.gif';
import loading3 from '@/assets/manage/wnLoading.gif';
export default {
  components: {
    CustomLoading
  },
  data() {
    return {
      dialogImageUrl: '',
      fileList: [],
      textarea: "",
      isLoading: false,
      yourImageUrl: loading1,
      yourImageUrls: [loading1, loading2, loading3]
    };
  },
  mounted() {
    let _this = this;
  },
  methods: {
    // 判断图片格式 和 文件大小
    beforeAvatarUploadImage(file) {
      // 该方法不起作用
      console.log("beforeAvatarUploadImage:", isJpeg);
      // || file.type == 'video/mp4'
      const isJpeg = file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpeg) {
        this.$message.error('请选择正确的文件格式上传');
      }
      return isJpeg;
    },
    // 选择文件时，往fileList里添加
    handleChange(file) {
      console.log(file.raw.type);
      const isJpeg = file.raw.type === 'image/png' || file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg';
      if (!isJpeg) {
        this.$message.error('jpg/png以外的文件将不会被上传');
      } else {
        this.fileList.push(file);
      }
    },
    handleRemove(file) {
      this.fileList.pop(file);
    },
    doOcr() {
      console.log("执行ocr...");
      if (this.fileList.length == 0) {
        this.$message.success('请先选择图片再识别');
        return;
      }
      const formData = new FormData();
      // 因为要传一个文件数组过去，所以要循环append
      if (this.fileList.length === 0) {
        formData.append('files', []);
      } else {
        this.fileList.forEach(file => {
          formData.append('files', file.raw);
        });
      }
      let _this = this;
      _this.isLoading = true;
      this.$axios.post('/photo/baidu/get_ocr_info', formData).then(res => {
        console.log("图片识别结果：", res);
        _this.textarea = res.data.data.result_str;
        _this.fileList = [];
        _this.isLoading = false;
      }).catch(res => {
        this.$message.success('图片识别失败！');
        _this.isLoading = false;
      });
    },
    getImageUrl() {
      let index = Math.floor(Math.random() * this.yourImageUrls.length);
      return this.yourImageUrls[index];
    }
  }
};