import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d197af92"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "footer"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass([item.cls, {
        on: index === $props.idx
      }]),
      onClick: $event => _ctx.$router.push(item.push)
    }, [_createElementVNode("img", {
      src: index === $props.idx ? item.iconSelect : item.icon
    }, null, 8, _hoisted_3), _createElementVNode("p", {
      class: _normalizeClass(['colorChange', {
        on: index === $props.idx
      }])
    }, _toDisplayString(item.name), 3)], 10, _hoisted_2);
  }), 256))]);
}