import BNav from '@/components/BottomNav.vue';
export default {
  name: 'VideoView',
  components: {
    BNav
  },
  data() {
    return {
      items: [],
      videoPath: ""
    };
  },
  beforeCreate() {},
  mounted() {
    console.log(this.$route.query);
    this.videoPath = this.$route.query.videoPath;
  }
};