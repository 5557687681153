import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useRouter } from 'vue-router';
import BNav from '@/components/BottomNav.vue';
import { Delete, Edit, Share, Upload, Download } from '@element-plus/icons-vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  setup() {
    return {
      Delete,
      Edit,
      Upload,
      Download
    };
  },
  name: 'MyPhotoListView',
  components: {
    BNav
  },
  data() {
    return {
      imgList: [],
      srcList: [],
      page: 1,
      pageSize: 20,
      total: 0,
      editVisible: false,
      deleteVisible: false,
      coverVisible: false,
      edit_remark: '',
      edit_id: 0,
      delete_id: 0,
      cover_id: 0,
      lastPageNum: 0,
      img_index: 0,
      default_remark: '',
      fileList: []
    };
  },
  mounted() {
    console.log("MyListView created");
    // 不再需要默认备注，没有就不显示
    // let _this = this
    // this.$axios({
    //   url: '/photo/select_default_remark',
    //   method: "POST",
    // }).then(function (response) {
    //   console.log(response.data)
    //   if (response.data.code == 200) {
    //     // _this.imgList = _this.imgList.concat(response.data.dataList)
    //     let remark_string = response.data.data
    //     console.log(remark_string)
    //     let remark_arr = remark_string.split(',')
    //     _this.default_remark = remark_arr[Math.floor(Math.random() * remark_arr.length + 1) - 1]
    //   } else {
    //     ElMessage(response.data.message)
    //   }
    //   _this.lastPageNum = response.data.lastPageNum
    // })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    this.select_photo_page();
  },
  methods: {
    show_more() {
      console.log("show_more");
      // 判断 total

      if (this.page >= this.lastPageNum) {
        return;
      }
      console.log("正常请求分页");
      this.page++;
      this.select_photo_page();
    },
    select_photo_page() {
      let _this = this;
      this.$axios({
        url: '/photo/select_photo_page',
        data: JSON.stringify({
          page: _this.page,
          pageSize: _this.pageSize
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          // _this.imgList = _this.imgList.concat(response.data.dataList)
          response.data.dataList.forEach(item => {
            item.index = _this.img_index;
            _this.imgList.push(item);
            _this.srcList.push(item.prev_paths);
            _this.img_index++;
          });
        } else {
          ElMessage(response.data.message);
        }
        _this.lastPageNum = response.data.lastPageNum;
      }).catch(function (error) {
        console.log(error);
      });
    },
    openDelete(id) {
      this.deleteVisible = true;
      this.delete_id = id;
    },
    saveDelete() {
      // 点击删除确认按钮
      this.deleteVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/del_photo',
        data: JSON.stringify({
          id: _this.delete_id
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          // 遍历并删除元素
          for (let i = _this.imgList.length - 1; i >= 0; i--) {
            _this.imgList[i].index = i - 1;
            if (_this.imgList[i].id === _this.delete_id) {
              _this.imgList.splice(i, 1);
              _this.srcList.splice(i, 1);
              break;
            }
          }
          _this.img_index--;
        } else {
          ElMessage(response.data.message);
        }
        _this.delete_id = null;
      }).catch(function (error) {
        console.log(error);
        ElMessage(response.data.error);
      });
    },
    openEdit(id, remark) {
      this.editVisible = true;
      this.edit_id = id;
      this.edit_remark = remark;
    },
    openCover(id) {
      this.coverVisible = true;
      this.cover_id = id;
    },
    saveEdit() {
      // 点击编辑备注按钮
      this.editVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/update_photo_remark',
        data: JSON.stringify({
          remark: _this.edit_remark,
          id: _this.edit_id
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          _this.imgList.forEach(item => {
            if (item.id == _this.edit_id) {
              item.remark = _this.edit_remark;
            }
          });
        } else {
          ElMessage(response.data.message);
        }
        _this.edit_remark = "";
        _this.edit_id = null;
      }).catch(function (error) {
        console.log(error);
      });
    },
    detailPhoto(detailPhoto) {
      // 查询详情原图
      // this.imgList.forEach((item) => {
      //   this.srcList.push(item.prev_paths)
      // })
    },
    download(o) {
      // console.log("downloadPath:", detailPhoto)
      // window.open(detailPhoto);
      let href = o.path; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      let down_name = o.filename + o.create_time.replace(" ", '') + "." + o.ext; // he设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      console.log("down_name:", down_name);
      this.downloadByBlob(href, down_name);
    },
    downloadByBlob(url, name) {
      let image = new Image();
      image.setAttribute('crossorigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob(blob => {
          let url = URL.createObjectURL(blob);
          this.download1(url, name); // 用完释放URL对象 
          URL.revokeObjectURL(url);
        });
      };
    },
    download1(href, name) {
      let eleLink = document.createElement('a');
      eleLink.download = name;
      eleLink.href = href;
      eleLink.click();
      eleLink.remove();
    },
    openVideo(ext, path) {
      console.log(ext);
      if (ext == "mp4") {
        // 跳转路由
        this.$router.push({
          name: 'myVideo',
          query: {
            videoPath: path
          }
        });
      }
    },
    // 判断图片格式 和 文件大小
    beforeAvatarUploadImage(file) {
      // 该方法不起作用
      console.log("beforeAvatarUploadImage:", isJpeg);
      const isJpeg = file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpeg) {
        this.$message.error('请选择正确的文件格式上传');
      }
      return isJpeg;
    },
    // 选择文件时，往fileList里添加
    handleChange(file) {
      console.log(file.raw.type);
      const isJpeg = file.raw.type === 'image/png' || file.raw.type === 'image/jpg' || file.raw.type === 'image/jpeg';
      if (!isJpeg) {
        this.$message.error('jpg/png以外的文件将不会被上传');
      } else {
        this.fileList.push(file);
        // 判断如果大于2M，则压缩
        const isLt2M = file.size > 1024 * 1024 * 2;
        if (isLt2M) {
          const that = this;
          // 回显
          // this.dialogImageUrl = URL.createObjectURL(file.raw)
          // 调用自定义的压缩方法
          compress(file.raw, function (val) {
            // 图片格式： blob => file
            console.log("压缩前:", file.raw);
            let newFile = new window.File([val], file.name, {
              type: file.raw.type
            });
            newFile.uid = file.uid;
            file.raw = newFile;
            // 新增属性(file)并赋值(that.uploadParams是俺自定义传给后端的值，根据自己项目情况赋值)
            // that.$set(that.uploadParams, 'file', newFile)
            // // 查看压缩后的图片路径
            console.log("压缩后:", newFile);
            console.log('newFileURL', URL.createObjectURL(val));
          });
        }
      }
      ElMessage("图片已准备就续：" + this.fileList.length);
    },
    handleRemove(file) {
      this.fileList.pop(file);
    },
    // 批量上传
    uploadForm() {
      // this.$refs.upload.submit()
      if (this.fileList.length === 0) {
        this.$message.warning('请选取文件');
        return;
      }
      this.coverVisible = false;
      const formData = new FormData();
      // 因为要传一个文件数组过去，所以要循环append
      this.fileList.forEach(file => {
        formData.append('files', file.raw);
      });
      formData.append("cover_id", this.cover_id);
      formData.append("share", 'yes');
      this.$axios.post('/photo/upload_img_cover', formData).then(res => {
        this.$message.success('封面上传成功！');
        setTimeout(() => {
          this.dialogVisible_up_image = false;
        }, 200);
      }).catch(res => {
        this.$message.success('封面上传失败！');
        setTimeout(() => {
          this.dialogVisible_up_image = false;
        }, 200);
      });
      // 清空图片列表（一定要清空，否则上传成功后还是会调用handleChange（）函数，上传成功后列表中还存在图片）
      this.fileList = [];
      this.share = false;
    }
  }
};