import "core-js/modules/es.array.push.js";
import { getYearAgo, getYearHalf, getLastMonth, getFirstDay, getLastDay, getFirstLastDay1, getFirstLastDay2 } from '@/common/dateUtils';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  setup() {
    return {};
  },
  components: {},
  data() {
    const generateData = _ => {
      const data = [];
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `备选项 ${i}`
        });
      }
      return data;
    };
    return {
      dialogVisible_up_add_clock_attr: false,
      dialogVisible_up_clock_set: false,
      add_clock_attr_name: "",
      tableData: [],
      transferData: generateData(),
      transferValue: [],
      dialogVisible_up_query_statis: false,
      yearMonth: "",
      yearMonthArr: [],
      statisTableData: []
    };
  },
  methods: {
    openAddClockAttr() {
      this.dialogVisible_up_add_clock_attr = true;
    },
    addClockAttr() {
      // 点击添加目录按钮
      this.dialogVisible_up_add_clock_attr = false;
      let _this = this;
      this.$axios({
        url: '/photo/clock/add',
        data: JSON.stringify({
          name: _this.add_clock_attr_name
        }),
        method: "POST"
      }).then(function (response) {
        _this.add_clock_attr_name = "";
        console.log(response.data);
        if (response.data.code == 200) {
          _this.query_clocks(_this);
          // 查询我的设置
          _this.$axios({
            url: '/photo/clock/query_clock_attrs',
            method: "POST"
          }).then(function (res) {
            console.log(res.data);
            if (res.data.code == 200) {
              _this.transferData = [];
              res.data.dataList.forEach(element => {
                _this.transferData.push({
                  'value': element['id'],
                  'desc': element['name']
                });
              });
            }
          });
        }
        ElMessage(response.data.message);
      });
    },
    openClockSet() {
      this.dialogVisible_up_clock_set = true;
    },
    setClock() {
      // console.log("values:", this.transferValue)
      // console.log("transferData:", this.transferData)

      let clock_str_arr = [];
      this.transferData.forEach(item => {
        if (this.transferValue.indexOf(item.value) != -1) {
          clock_str_arr.push(item.desc);
        }
      });
      let clock_ids = this.transferValue.join(",");
      let clock_strs = clock_str_arr.join(",");
      console.log("clock_ids:", clock_ids);
      console.log("clock_strs:", clock_strs);
      if (clock_ids != "" && clock_strs != "") {
        let _this = this;
        let yearMonth = getLastDay().substring(0, 7);
        _this.$axios({
          url: '/photo/clock/set_clock',
          data: JSON.stringify({
            'clock_ids': clock_ids,
            'clock_strs': clock_strs,
            'year_month': yearMonth
          }),
          method: "POST"
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 200) {
            ElMessage("一定要加油鸭");
            _this.query_clocks(_this);
            _this.yearMonthChange();
            _this.dialogVisible_up_clock_set = false;
          }
        });
      } else {
        ElMessage("请选择打卡项");
      }
    },
    changeSw(item) {
      console.log("item:", item);
      let status = item.status == true ? "yes" : "no";
      let clock_id = item.id;
      let _this = this;
      _this.$axios({
        url: '/photo/clock/kick',
        data: JSON.stringify({
          'id': clock_id,
          'status': status
        }),
        method: "POST"
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code == 200 && res.data.data.status == "yes") {
          ElMessage("恭喜完成打卡");
        }
      });
    },
    handleChange(value, direction, movedKeys) {
      // 已选，方向（left,right），目标数据（暂时不用）
      console.log(value, direction, movedKeys);
    },
    query_clocks(_this) {
      if (_this == null) {
        _this = this;
      }
      _this.$axios({
        url: '/photo/clock/query_clocks',
        method: "POST"
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code == 200) {
          _this.tableData = [];
          _this.transferValue = [];
          res.data.dataList.forEach(element => {
            _this.tableData.push({
              'id': element['id'],
              'name': element['clock_str'],
              'status': element['status'] == "yes" ? true : false
            });
            _this.transferValue.push(element['clock_id']);
          });
        }
      });
    },
    openQueryStatis() {
      this.dialogVisible_up_query_statis = true;
      if (this.yearMonth == undefined) {
        console.log("没有选择年月");
        return;
      }
      this.queryQueryStatis(this);
    },
    yearMonthChange() {
      console.log("当前年月:", this.yearMonth);
      if (this.yearMonth == undefined) {
        console.log("没有选择年月");
        return;
      }
      this.queryQueryStatis(this);
    },
    percentFormat(percentage) {
      if (percentage >= 85) {
        return "优秀";
      } else if (percentage >= 60) {
        return "及格";
      } else {
        return "加油鸭";
      }
      // return percentage >= 85 ? '优秀' : `${percentage}%`;
    },
    queryQueryStatis(_this) {
      let dateArr = getFirstLastDay2(_this.yearMonth);
      _this.$axios({
        url: '/photo/clock/query_statis',
        data: JSON.stringify({
          start_date: dateArr[0],
          end_date: dateArr[1]
        }),
        method: "POST"
      }).then(function (res) {
        console.log("查询记账列表成功", res);
        _this.statisTableData = [];
        res.data.dataList.forEach(element => {
          _this.statisTableData.push({
            'clock_str': element['clock_str'],
            'do_count': element['do_count'],
            'all_count': element['all_count'],
            'id': element['id'],
            'username': element['username']
          });
        });
      }).catch(function (error) {
        console.log(error);
      });
    }
  },
  mounted() {
    // 查询当前设置项
    let _this = this;
    this.query_clocks(this);

    // 查询我的设置
    _this.$axios({
      url: '/photo/clock/query_clock_attrs',
      method: "POST"
    }).then(function (res) {
      console.log(res.data);
      if (res.data.code == 200) {
        _this.transferData = [];
        res.data.dataList.forEach(element => {
          _this.transferData.push({
            'value': element['id'],
            'desc': element['name']
          });
        });
      }
    });
    const monthList = getLastMonth();
    _this.yearMonthArr = [];
    monthList.forEach(element => {
      _this.yearMonthArr.push({
        'value': element,
        'label': element
      });
    });

    // 默认本月
    _this.yearMonth = getLastDay().substring(0, 7);
    // 开始查询本月数据
    _this.yearMonthChange();
  },
  created() {},
  watch: {}
};