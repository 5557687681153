export default {
  props: ['idx'],
  data() {
    return {
      items: [{
        cls: "chat",
        name: "智能对话",
        push: "/myChat",
        icon: require("@/assets/s11.png"),
        iconSelect: require("@/assets/s12.png")
      }, {
        cls: "tools",
        name: "工具",
        push: "/tools",
        icon: require("@/assets/s21.png"),
        iconSelect: require("@/assets/s22.png")
      }, {
        cls: "myPhotoList",
        name: "照片列表",
        push: "/myPhotoList",
        icon: require("@/assets/s31.png"),
        iconSelect: require("@/assets/s32.png")
      }, {
        cls: "folder_manage",
        name: "管理",
        push: "/folder_manage",
        icon: require("@/assets/s41.png"),
        iconSelect: require("@/assets/s42.png")
      }]
    };
  }
};