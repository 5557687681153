import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-82389276"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "sudoku_row"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "dialog-footer"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
const _hoisted_7 = {
  class: "dialog-footer"
};
const _hoisted_8 = {
  class: "dialog-footer"
};
const _hoisted_9 = {
  class: "dialog-footer"
};
const _hoisted_10 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_BNav = _resolveComponent("BNav");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.sudokus, (sudoku, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["sudoku_item", $data.curSelect == sudoku.id ? 'opacity' : '']),
      key: index,
      onClick: $event => $options.start(index)
    }, [_createElementVNode("img", {
      src: sudoku.img_src,
      width: "100",
      height: "100"
    }, null, 8, _hoisted_4), _createTextVNode(" " + _toDisplayString(sudoku.name), 1)], 10, _hoisted_3);
  }), 128))]), _createVNode(_component_el_dialog, {
    title: "成语大全",
    modelValue: $data.dialogVisible_up_chengyu,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.dialogVisible_up_chengyu = $event),
    width: "90%"
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_5, [_createVNode(_component_el_button, {
      onClick: _cache[2] || (_cache[2] = $event => $data.dialogVisible_up_chengyu = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[3] || (_cache[3] = $event => $options.goQueryChengyu($data.chengyu_content))
    }, {
      default: _withCtx(() => [_createTextVNode("确 定")]),
      _: 1
    })])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "70px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "成语:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.chengyu_content,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.chengyu_content = $event),
          modelModifiers: {
            trim: true
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "解释:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "textarea",
          modelValue: $data.chengyu_content_result,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.chengyu_content_result = $event),
          placeholder: "即将展示成语解释",
          autosize: {
            minRows: 2,
            maxRows: 20
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
    title: "汉语词典",
    modelValue: $data.dialogVisible_up_dict,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => $data.dialogVisible_up_dict = $event),
    width: "90%"
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_6, [_createVNode(_component_el_button, {
      onClick: _cache[7] || (_cache[7] = $event => $data.dialogVisible_up_dict = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[8] || (_cache[8] = $event => $options.goQueryDict($data.dict_content))
    }, {
      default: _withCtx(() => [_createTextVNode("确 定")]),
      _: 1
    })])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "70px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "汉字:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.dict_content,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.dict_content = $event),
          modelModifiers: {
            trim: true
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "解释:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "textarea",
          modelValue: $data.dict_content_result,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.dict_content_result = $event),
          placeholder: "即将展示汉字解释",
          autosize: {
            minRows: 2,
            maxRows: 20
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
    title: "每日一笑",
    modelValue: $data.dialogVisible_up_joke,
    "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => $data.dialogVisible_up_joke = $event),
    width: "90%"
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_7, [_createVNode(_component_el_button, {
      onClick: _cache[11] || (_cache[11] = $event => $data.dialogVisible_up_joke = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.goQueryJoke
    }, {
      default: _withCtx(() => [_createTextVNode("随 机")]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "70px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "内容:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "textarea",
          modelValue: $data.joke_content_result,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $data.joke_content_result = $event),
          placeholder: "即将展示内容",
          autosize: {
            minRows: 2,
            maxRows: 20
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
    title: "IP解析",
    modelValue: $data.dialogVisible_up_ip,
    "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => $data.dialogVisible_up_ip = $event),
    width: "90%"
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_8, [_createVNode(_component_el_button, {
      onClick: _cache[15] || (_cache[15] = $event => $data.dialogVisible_up_ip = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.goQuerySelfIp
    }, {
      default: _withCtx(() => [_createTextVNode("查询本机")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.goQueryIp
    }, {
      default: _withCtx(() => [_createTextVNode("查询指定IP")]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "70px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "IP:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ip_content,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $data.ip_content = $event),
          modelModifiers: {
            trim: true
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "解析:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "textarea",
          modelValue: $data.ip_content_result,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => $data.ip_content_result = $event),
          placeholder: "即将展示IP内容",
          autosize: {
            minRows: 2,
            maxRows: 20
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
    title: "每日精句",
    modelValue: $data.dialogVisible_up_sentence,
    "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => $data.dialogVisible_up_sentence = $event),
    width: "90%"
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_9, [_createVNode(_component_el_button, {
      onClick: _cache[18] || (_cache[18] = $event => $data.dialogVisible_up_sentence = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.goQuerySentence
    }, {
      default: _withCtx(() => [_createTextVNode("出现吧句子")]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "70px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "句子:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "textarea",
          modelValue: $data.sentence_content_result,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => $data.sentence_content_result = $event),
          placeholder: "即将展示句子",
          autosize: {
            minRows: 2,
            maxRows: 20
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
    title: "歇后语",
    modelValue: $data.dialogVisible_up_xiehouyu,
    "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => $data.dialogVisible_up_xiehouyu = $event),
    width: "90%"
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_10, [_createVNode(_component_el_button, {
      onClick: _cache[22] || (_cache[22] = $event => $data.dialogVisible_up_xiehouyu = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.goQueryRandomXiehouyu
    }, {
      default: _withCtx(() => [_createTextVNode("随机一条")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.goQueryXiehouyu
    }, {
      default: _withCtx(() => [_createTextVNode("模糊查询")]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createVNode(_component_el_form, {
      "label-width": "70px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "查询:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.xiehouyu_content,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => $data.xiehouyu_content = $event),
          modelModifiers: {
            trim: true
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "结果:"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          type: "textarea",
          modelValue: $data.xiehouyu_content_result,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => $data.xiehouyu_content_result = $event),
          placeholder: "即将展示歇后语",
          autosize: {
            minRows: 2,
            maxRows: 20
          }
        }, null, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]), _createVNode(_component_BNav, {
    idx: 1
  })], 64);
}