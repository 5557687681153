import { createRouter, createWebHashHistory } from 'vue-router'
import ManageView from '../views/ManageView'
import LoginView from '../views/LoginView'
import MyChatView from '../views/MyChatView'
import MyPhotoListView from '../views/MyPhotoListView'
import VideoView from '../views/VideoView'
import XlzsDetailView from '../views/XlzsDetailView'
import ToolsView from '@/views/ToolsView.vue'
import Editor from '@/views/Editor.vue'
import MyEditListView from '@/views/MyEditListView.vue'
import AccountView from '@/views/AccountView.vue'
import AccountListView from '@/views/AccountListView.vue'
import ClockSetView from '@/views/ClockSetView.vue'
import OCRView from '@/views/OCRView.vue'
import MySayListView from '@/views/MySayListView.vue'

const routes = [
  {
    path: '/',
    name: '',
    component: MyChatView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/folder_manage',
    name: 'folder_manage',
    component: ManageView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/myChat',
    name: 'myChat',
    component: MyChatView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/myPhotoList',
    name: 'myPhotoList',
    component: MyPhotoListView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/myVideo',
    name: 'myVideo',
    component: VideoView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },

  },
  {
    path: '/xlzsDetail',
    name: 'xlzsDetail',
    component: XlzsDetailView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
  , {
    path: '/edit',
    name: 'edit',
    component: Editor,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  }
  , {
    path: '/editList',
    name: 'editList',
    component: MyEditListView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  }, {
    path: '/accountList',
    name: 'accountList',
    component: AccountListView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/clockSet',
    name: 'clockSet',
    component: ClockSetView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },
  {
    path: '/doOcr',
    name: 'doOcr',
    component: OCRView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  }, {
    path: '/sayList',
    name: 'sayList',
    component: MySayListView,
    meta: {
      requireAuth: true,  // 添加该字段, 表示进入这个路由是需要登录的
    },
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
