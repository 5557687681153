import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-af1084dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { reactive, onBeforeMount, onMounted, ref, toRaw, watch, onBeforeUpdate } from 'vue';
import { useRouter } from 'vue-router';
import { inject } from 'vue';
export default {
  __name: 'XlzsDetailView',
  props: ['value'],
  emits: ['updateValue'],
  setup(__props, {
    emit: __emit
  }) {
    const router = useRouter();
    const props = __props;
    const emit = __emit;
    const content = ref("");
    const myQuillEditor = ref();
    // let selNum = router.currentRoute.value.query.selNum
    let video_path = "";
    const axios = inject('$axios');
    const fileBtn = ref();
    const data = reactive({
      content: '',
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike'], [{
            'size': ['small', false, 'large', 'huge']
          }], [{
            'font': []
          }], [{
            'align': []
          }], [{
            'list': 'ordered'
          }, {
            'list': 'bullet'
          }], [{
            'indent': '-1'
          }, {
            'indent': '+1'
          }], [{
            'header': 1
          }, {
            'header': 2
          }], ['image'], [{
            'direction': 'rtl'
          }], [{
            'color': []
          }, {
            'background': []
          }]]
        },
        placeholder: '请输入内容...'
      }
    });
    onBeforeUpdate(() => {});

    // 抛出更改内容，此处避免出错直接使用文档提供的getHTML方法
    const setValue = () => {
      const text = toRaw(myQuillEditor.value).getHTML();
    };
    const openVideo = path => {
      // 跳转路由
      router.push({
        name: 'myVideo',
        query: {
          videoPath: path
        }
      });
    };
    const quClick = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    onMounted(() => {
      const quill = toRaw(myQuillEditor.value).getQuill();
      if (myQuillEditor.value) {
        quill.getModule('toolbar');
        quill.enable(false);
      }
    });
    onBeforeMount(() => {
      // 初始化内容(请求接口在上一个页面执行，利用localStorage来传递数据)
      // axios({
      //   url: '/photo/query_zs',
      //   data: JSON.stringify({
      //     selNum: selNum,
      //   }),
      //   method: "POST",
      // }).then(function (response) {
      //   console.log(response.data)
      //   if (response.data.code == 200) {
      //     console.log("接口返回的content:", response.data.data.content)
      //     content.value = response.data.data.content
      //     video_path = response.data.data.video_path
      //   } else {
      //     ElMessage(response.data.message)
      //   }
      // })
      content.value = localStorage.getItem('xlzsContent');
      video_path = localStorage.getItem('xlzxVideoPath');
      // content.value = "<p>这是初始化</p>"
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_unref(QuillEditor), {
        ref_key: "myQuillEditor",
        ref: myQuillEditor,
        theme: "snow",
        content: content.value,
        "onUpdate:content": [_cache[0] || (_cache[0] = $event => content.value = $event), _cache[1] || (_cache[1] = $event => setValue())],
        options: data.editorOption,
        contentType: "html",
        onClick: _cache[2] || (_cache[2] = $event => quClick($event))
      }, null, 8, ["content", "options"])]), _createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push('/folder_manage'))
      }, {
        default: _withCtx(() => [_createTextVNode("返 回")]),
        _: 1
      }), _unref(video_path) != '' ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        onClick: _cache[4] || (_cache[4] = $event => openVideo(_unref(video_path)))
      }, {
        default: _withCtx(() => [_createTextVNode("视频详情")]),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};