import "core-js/modules/es.array.push.js";
import { Delete, Edit, Share, Upload, Download } from '@element-plus/icons-vue';
import { getYearAgo, getYearHalf, getLastMonth, getFirstDay, getLastDay, getFirstLastDay1, getFirstLastDay2 } from '@/common/dateUtils';
import CustomLoading from '@/components/my/CustomLoading.vue';
import loading1 from '@/assets/manage/dogLoading.gif';
import loading2 from '@/assets/manage/waterLoading.gif';
import loading3 from '@/assets/manage/wnLoading.gif';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  components: {
    CustomLoading
  },
  data() {
    return {
      yearMonth: "",
      options: [],
      yearMonthArr: [],
      tableData: [],
      updateId: null,
      updateMoney: null,
      updateCategoryId: null,
      updateRemark: null,
      updateCreateDate: "",
      dialogVisible_up_account: false,
      operateEnable: false,
      totalMoney: 0,
      isLoading: false,
      yourImageUrl: loading1,
      yourImageUrls: [loading1, loading2, loading3]
    };
  },
  mounted() {
    let _this = this;
    const monthList = getLastMonth();
    _this.yearMonthArr = [];
    monthList.forEach(element => {
      _this.yearMonthArr.push({
        'value': element,
        'label': element
      });
    });

    // 默认本月
    _this.yearMonth = getLastDay().substring(0, 7);
    // 开始查询本月数据
    _this.yearMonthChange();
    _this.$axios({
      url: '/photo/attr/list',
      data: JSON.stringify({
        category: 'account'
      }),
      method: "POST"
    }).then(function (res) {
      console.log("查询记账分类成功", res);
      _this.options = [];
      res.data.dataList.forEach(element => {
        _this.options.push({
          'value': element['id'],
          'label': element['name']
        });
      });
    }).catch(function (error) {
      console.log(error);
      ElMessage(response.data.error);
    });

    // console.log("getYearAgo:", getYearAgo())
    // getYearAgo: 2023/7/23 09:33:57

    // console.log("getYearHalf:", getYearHalf())
    // getYearHalf: 2024/1/23 09:33:57

    // console.log("getLastMonth:", getLastMonth())
    // getLastMonth: (13) ['2024-07', '2024-06', '2024-05', '2024-04', '2024-03', '2024-02', '2024-01', '2023-12', '2023-11', '2023-10', '2023-09', '2023-08', '2023-07']

    // console.log("getFirstDay:", getFirstDay())
    // getFirstDay: 2024-07-01

    // console.log("getLastDay:", getLastDay())
    // getLastDay: 2024-07-31

    // let currentDate = new Date()
    // currentDate.setMonth(currentDate.getMonth() -1 )
    // console.log("getFirstLastDay1:", getFirstLastDay1(currentDate))
    // getFirstLastDay1: (2) ['2024-06-01', '2024-06-30']

    // console.log("getFirstLastDay1:", getFirstLastDay2("2024-06"))
    // getFirstLastDay1: (2) ['2024-06-01', '2024-06-30']
  },
  methods: {
    yearMonthChange() {
      console.log("当前年月:", this.yearMonth);
      if (this.yearMonth == undefined) {
        console.log("没有选择年月");
        return;
      }
      this.operateEnable = false;
      let _this = this;
      let dateArr = getFirstLastDay2(this.yearMonth);
      _this.$axios({
        url: '/photo/account/query_list',
        data: JSON.stringify({
          start_date: dateArr[0],
          end_date: dateArr[1]
        }),
        method: "POST"
      }).then(function (res) {
        console.log("查询记账列表成功", res);
        _this.tableData = [];
        res.data.dataList.forEach(element => {
          _this.tableData.push({
            'date': element['create_date'].substring(0),
            'money': element['money'],
            'category': element['category'],
            'remark': element['remark'],
            'prev_paths': element['prev_paths'],
            'id': element['id']
          });
        });
      }).catch(function (error) {
        console.log(error);
        ElMessage(response.data.error);
      });
    },
    queryTheMoney() {
      console.log("查询指定月：");
      if (this.yearMonth == undefined) {
        ElMessage("请选择指定月份后再查询");
        return;
      }
      this.queryData(getFirstLastDay2(this.yearMonth), this);
    },
    querySelfYear() {
      console.log("查询本年：");
      let year = new Date().getFullYear();
      this.queryData([year + "-01-01", year + "-12-31"], this);
    },
    queryLastYear() {
      console.log("查询去年：");
      let year = new Date().getFullYear() - 1;
      this.queryData([year + "-01-01", year + "-12-31"], this);
    },
    queryData(dateArr, _this) {
      _this.operateEnable = true;
      _this.isLoading = true;
      _this.$axios({
        url: '/photo/account/query_statis',
        data: JSON.stringify({
          start_date: dateArr[0],
          end_date: dateArr[1]
        }),
        method: "POST"
      }).then(function (res) {
        console.log("查询记账列表成功", res);
        _this.tableData = [];
        _this.totalMoney = 0;
        res.data.dataList.forEach(element => {
          _this.tableData.push({
            'date': dateArr[0].substring(0, 7) + "~ " + dateArr[1].substring(0, 7),
            'money': element['money'],
            'category': element['category'],
            'remark': '',
            'prev_paths': ''
          });
          _this.totalMoney = _this.totalMoney + element['money'];
        });
        _this.isLoading = false;
      }).catch(function (error) {
        console.log(error);
        _this.isLoading = false;
      });
    },
    imgShow(imgUrl) {
      const image = new Image();
      image.src = imgUrl;
      image.onload = () => {
        //创建弹出层
        const previewContatiner = document.createElement('div');
        previewContatiner.style.position = 'fixed';
        previewContatiner.style.top = 0;
        previewContatiner.style.bottom = 0;
        previewContatiner.style.left = 0;
        previewContatiner.style.right = 0;
        previewContatiner.style.zIndex = 9999;
        previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)';
        previewContatiner.style.display = 'flex';
        previewContatiner.style.justifyContent = 'center';
        previewContatiner.style.alignItems = 'center';
        document.body.appendChild(previewContatiner);
        //在弹出层增加图片
        const previewImage = document.createElement('img');
        previewImage.src = imgUrl;
        previewImage.style.maxWidth = '90%';
        previewImage.style.maxHeight = '90%';
        previewImage.style.zIndex = 9999;
        previewContatiner.appendChild(previewImage);
        //点击弹出层，关闭预览
        previewContatiner.addEventListener('click', () => {
          document.body.removeChild(previewContatiner);
        });
      };
      image.onerror = function () {
        console.log('图片加载失败');
      };
    },
    deleteAccount(id) {
      let _this = this;
      console.log("删除id：", id);
      this.$confirm('删除该账目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        const removeIndex = _this.tableData.findIndex(item => item['id'] == id);
        console.log("要删除的index:", removeIndex);
        if (removeIndex !== -1) {
          _this.tableData.splice(removeIndex, 1);
        }
        _this.$axios({
          url: '/photo/account/del_info',
          data: JSON.stringify({
            'id': id
          }),
          method: "POST"
        }).then(function (res) {
          console.log("删除记账成功", res);
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    openUploadAccount(id) {
      console.log("updateID:", id);
      let _this = this;
      this.updateId = id;
      this.dialogVisible_up_account = true;
      _this.$axios({
        url: '/photo/account/query_info',
        data: JSON.stringify({
          'id': id
        }),
        method: "POST"
      }).then(function (res) {
        console.log("查询记账信息成功", res);
        _this.updateMoney = res.data.data.money;
        _this.updateCategoryId = res.data.data.category_id;
        _this.updateRemark = res.data.data.remark;
        _this.updateCreateDate = res.data.data.create_date;
      }).catch(function (error) {
        console.log(error);
      });
    },
    updateAccount() {
      let _this = this;
      _this.$axios({
        url: '/photo/account/update_info',
        data: JSON.stringify({
          'id': _this.updateId,
          'money': _this.updateMoney,
          'category_id': _this.updateCategoryId,
          'remark': _this.updateRemark,
          'create_date': _this.updateCreateDate
        }),
        method: "POST"
      }).then(function (res) {
        console.log("修改记账信息成功", res);
        ElMessage("修改成功");
        _this.tableData.forEach(element => {
          if (element['id'] == res.data.data.id) {
            element['money'] = res.data.data.money;
            element['category_id'] = res.data.data.category_id;
            element['category'] = res.data.data.category;
            element['remark'] = res.data.data.remark;
            element['date'] = res.data.data.create_date;
          }
        });
        _this.updateId = null;
        _this.dialogVisible_up_account = false;
      }).catch(function (error) {
        console.log(error);
        ElMessage("修改失败");
      });
    },
    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2);
          sums[index] += ' 元';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    getImageUrl() {
      let index = Math.floor(Math.random() * this.yourImageUrls.length);
      return this.yourImageUrls[index];
    }
  }
};