import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44e5e808"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "myChat"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_QfChat = _resolveComponent("QfChat");
  const _component_BNav = _resolveComponent("BNav");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_QfChat)]), _createVNode(_component_BNav, {
    idx: 0
  })], 64);
}