import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { Delete, Edit, Share, Upload, Download } from '@element-plus/icons-vue';
export default {
  setup() {
    return {
      Delete,
      Edit,
      Upload,
      Download
    };
  },
  name: 'MyEditListView',
  components: {},
  data() {
    return {
      radioArray: [],
      //这个是必须的，你选中的每一题的数据都会存在这个数组里去，没有这个会有问题
      shoarr: [],
      //这个是事先命名的一个数组，因为有些人它有些题，他不确定，说的难听点：选择困难症，每个都点一下，这样会有大量数据，存到这个数组里进行去重操作(无论有多少数据，都以最后一个选择的为准
      category_list: [],
      editList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      lastPageNum: 0,
      category_id: 0,
      deleteVisible: false,
      delete_id: null,
      doc_last_id: null
    };
  },
  mounted() {
    console.log("MyEditListView created");
    let _this = this;
    this.$axios({
      url: '/photo/attr/list',
      data: JSON.stringify({
        category: 'doc'
      }),
      method: "POST"
    }).then(res => {
      if (_this.category_list.length == 0) {
        _this.category_list.push({
          'label': 0,
          'name': '全部'
        });
        res.data.dataList.forEach(element => {
          _this.category_list.push({
            'label': element['id'],
            'name': element['name']
          });
        });
      }
      console.log("查询文档分类成功", res);
    }).catch(res => {
      ElMessage(res.data.message);
    });
    if (localStorage.getItem("doc_id") != null && localStorage.getItem("doc_content") != null) {
      _this.$axios({
        url: '/photo/doc/add',
        data: JSON.stringify({
          content: localStorage.getItem("doc_content"),
          doc_id: localStorage.getItem("doc_id")
        }),
        method: "POST"
      }).then(res => {
        localStorage.removeItem("doc_id");
        localStorage.removeItem("doc_content");
        console.log("保存暂存的文档成功");
      });
    }
    this.select_doc_page();
  },
  methods: {
    handleRadioChanges(index, strnum) {
      //index,选中题目的下标，strnum你选中的那个选项，实际上也是个index
      let data = {
        name: 0,
        val: 0
      };
      data.name = index;
      data.val = parseInt(strnum);
      this.shoarr.push(data); //将我选中的哪一题，哪个选项存到实现准备好的数组shoarr
      let myarr = this.shoarr;
      for (var i = 0; i < myarr.length; i++) {
        for (var j = i + 1; j < myarr.length; j++) {
          if (myarr[i].name == myarr[j].name) {
            myarr.splice(i, 1);
            j--;
          }
        }
      } //数组去重，以最后一个选中为准
      this.shoarr = myarr;
    },
    radioClick(index) {
      console.log("redioIndex:", index);
      this.doc_last_id = null;
      if (index != this.category_id) {
        this.category_id = index;
        this.editList = [];
        this.page = 1;
        this.select_doc_page();
      }
    },
    show_more() {
      console.log("show_more");
      // 判断 total

      console.log("正常请求分页");
      // this.page++
      this.select_doc_page();
    },
    select_doc_page() {
      let _this = this;
      this.$axios({
        url: '/photo/doc/select_doc_page',
        data: JSON.stringify({
          'page': _this.page,
          'pageSize': _this.pageSize,
          'category_id': _this.category_id,
          'doc_id': _this.doc_last_id
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200 && response.data.dataList.length > 0) {
          response.data.dataList.forEach(item => {
            item['content'] = _this.htmlToText(item['content']);
            if (item['content'].length > 14) {
              item['content'] = item['category'] + "：" + item['content'].substring(0, 14) + "...";
            }
            _this.editList.push(item);
            _this.doc_last_id = item['id'];
          });
        }
        _this.lastPageNum = response.data.lastPageNum;
      }).catch(function (error) {
        console.log(error);
      });
    },
    htmlToText(htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      return doc.body.textContent || "";
    },
    openDelete(id) {
      this.deleteVisible = true;
      this.delete_id = id;
    },
    saveDelete() {
      // 点击删除确认按钮
      this.deleteVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/doc/del_doc',
        data: JSON.stringify({
          'doc_id': _this.delete_id
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          const removeIndex = _this.editList.findIndex(item => item['id'] == _this.delete_id);
          console.log("要删除的index:", removeIndex);
          if (removeIndex !== -1) {
            _this.editList.splice(removeIndex, 1);
          }
        } else {
          ElMessage(response.data.message);
        }
        _this.delete_id = null;
      }).catch(function (error) {
        console.log(error);
        ElMessage(response.data.error);
      });
    },
    openEdit(id) {
      let _this = this;
      this.$axios({
        url: '/photo/doc/get_info',
        data: JSON.stringify({
          'doc_id': id
        }),
        method: "POST"
      }).then(res => {
        localStorage.setItem('doc_id', id);
        localStorage.setItem("doc_content", res.data.data.content);
        _this.$router.push({
          name: 'edit'
        });
      }).catch(res => {
        ElMessage(res.data.message);
      });
    }
  }
};