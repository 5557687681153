/**
    * 获取当前时间的一年前时间
    */
export function getYearAgo() {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1)
    let localeString = currentDate.toLocaleString();
    console.log("当前时间的一年前时间:" + localeString);
    return localeString
}

/**
     * 获取当前时间的半年前时间
     */
export function getYearHalf() {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    // currentDate.setDate(currentDate.getDate() -1)
    let localeString = currentDate.toLocaleString();
    console.log("当前时间的半年前时间:" + localeString);
    return localeString
}

/**
     * 获取当前时间至一年前所有的年月
     * @returns {*[]}
     * 如输出 ["2022-07", "2022-08", "2022-09", "2022-10", "2022-11", "2022-12", "2023-01", "2023-02", "2023-03", "2023-04", "2023-05", "2023-06", "2023-07"]
     */
export function getLastMonth() {
    let result = [];
    for (let i = 0; i < 13; i++) {
        const currentDate = new Date();
        currentDate.setDate(1);// 将当前时间的日期设置成第一天
        let month = currentDate.getMonth() - i;
        currentDate.setMonth(month)
        let returnMonth = currentDate.getMonth() + 1;
        returnMonth = returnMonth < 10 ? "0" + returnMonth : returnMonth;//自定义输出日期的格式
        // result.push(currentDate.getFullYear() + "年" + returnMonth+"月");
        result.push(currentDate.getFullYear() + "-" + returnMonth);
    }
    return result;
}


 // 获取当前月第一天
 export function getFirstDay() {
    const date = new Date();
    // 将当前时间的日期设置成第一天
    date.setDate(1);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    // 月份补 0
    m = m < 10 ? '0' + m : m;
    let d = date.getDate();
    // 日期补 0
    d = d < 10 ? '0' + d : d;
    let firstDay = [y, m, d].join('-');
    return firstDay
}


// 获取当前月最后一天
export function getLastDay() {
    const date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    // 月份补 0
    m = m < 10 ? '0' + m : m;
    // new Date(y, m, 0) 获取当前月的最后一天,参数 0 代表上个月的最后一天
    let d = new Date(y, m, 0).getDate();
    // 日期补 0
    d = d < 10 ? '0' + d : d;
    let lastDay = [y, m, d].join('-');
    return lastDay
}


// 获取当前月第一天和最后一天
export function getFirstLastDay1(date) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    // 设置为日期1号
    date.setDate(1);
    let firstDay = date.getDate();
    firstDay = firstDay < 10 ? '0' + firstDay : firstDay;
    let first = [y, m, firstDay].join('-');

    // 获取当前月的最后一天,参数 0 代表上个月的最后一天
    let lastDay = new Date(y, m, 0).getDate();
    let last = [y, m, lastDay].join('-');
    return [first , last]
}


// 获取当前月第一天和最后一天
export function getFirstLastDay2(dateString) {
    let year = Number(dateString.slice(0, 4));
    let month = Number(dateString.slice(5, 7));

    let date = new Date(year, month - 1);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    // 设置为日期1号
    date.setDate(1);
    let firstDay = date.getDate();
    firstDay = firstDay < 10 ? '0' + firstDay : firstDay;
    let first = [y, m, firstDay].join('-');

    // 获取当前月的最后一天,参数 0 代表上个月的最后一天
    let lastDay = new Date(year, month, 0).getDate();
    let last = [y, m, lastDay].join('-');
    return [first , last]
}