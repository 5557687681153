import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { Delete, Edit, Share, Upload, Download } from '@element-plus/icons-vue';
export default {
  setup() {
    return {
      Delete,
      Edit,
      Upload,
      Download
    };
  },
  name: 'MyEditListView',
  components: {},
  data() {
    return {
      radioArray: [],
      //这个是必须的，你选中的每一题的数据都会存在这个数组里去，没有这个会有问题
      shoarr: [],
      //这个是事先命名的一个数组，因为有些人它有些题，他不确定，说的难听点：选择困难症，每个都点一下，这样会有大量数据，存到这个数组里进行去重操作(无论有多少数据，都以最后一个选择的为准
      editList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      lastPageNum: 0,
      deleteVisible: false,
      delete_id: null
    };
  },
  mounted() {
    console.log("MySayListView created");
    this.select_say_page();
  },
  methods: {
    show_more() {
      console.log("show_more");
      // 判断 total

      if (this.page >= this.lastPageNum) {
        return;
      }
      console.log("正常请求分页");
      this.page++;
      this.select_say_page();
    },
    select_say_page() {
      let _this = this;
      this.$axios({
        url: '/photo/say/select_say_page',
        data: JSON.stringify({
          'page': _this.page,
          'pageSize': _this.pageSize
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200 && response.data.dataList.length > 0) {
          response.data.dataList.forEach(item => {
            item['content'] = item['username'] + "：" + item['content'];
            _this.editList.push(item);
          });
        }
        _this.lastPageNum = response.data.lastPageNum;
      }).catch(function (error) {
        console.log(error);
      });
    },
    openDelete(id) {
      this.deleteVisible = true;
      this.delete_id = id;
    },
    saveDelete() {
      // 点击删除确认按钮
      this.deleteVisible = false;
      let _this = this;
      this.$axios({
        url: '/photo/say/del_say',
        data: JSON.stringify({
          'say_id': _this.delete_id
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          ElMessage(response.data.message);
          const removeIndex = _this.editList.findIndex(item => item['id'] == _this.delete_id);
          console.log("要删除的index:", removeIndex);
          if (removeIndex !== -1) {
            _this.editList.splice(removeIndex, 1);
          }
        } else {
          ElMessage(response.data.message);
        }
        _this.delete_id = null;
      }).catch(function (error) {
        console.log(error);
        ElMessage(response.data.error);
      });
    }
  }
};