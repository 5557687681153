import QfChat from '@/components/my/QfChat.vue';
import BNav from '@/components/BottomNav.vue';
export default {
  name: 'MyChatView',
  components: {
    QfChat,
    BNav
  },
  mounted() {
    console.log("智能问答 created");
  }
};