import CustomLoading from '@/components/my/CustomLoading.vue';
import loading1 from '@/assets/manage/dogLoading.gif';
import loading2 from '@/assets/manage/waterLoading.gif';
import loading3 from '@/assets/manage/wnLoading.gif';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  components: {
    CustomLoading
  },
  data() {
    return {
      textarea1: '',
      textarea2: '',
      chat_id: 0,
      isLoading: false,
      yourImageUrl: loading1,
      yourImageUrls: [loading1, loading2, loading3]
    };
  },
  mounted() {},
  methods: {
    chat_me() {
      if (this.textarea1 == "") {
        ElMessage("请输入问题后提交！");
        return;
      }
      let _this = this;
      _this.isLoading = true;
      this.$axios({
        url: '/photo/chat_me',
        data: JSON.stringify({
          content: _this.textarea1
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code == 200) {
          _this.textarea2 = response.data.data.result;
          _this.chat_id = response.data.data.id;
        } else {
          ElMessage(response.data.message);
        }
        _this.isLoading = false;
      }).catch(function (error) {
        console.log(error);
        _this.isLoading = false;
      });
    },
    chat_last() {
      let _this = this;
      _this.isLoading = true;
      this.$axios({
        url: '/photo/chat_last',
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        _this.isLoading = false;
        if (response.data.code == 200) {
          _this.textarea2 = response.data.data.result;
          _this.chat_id = response.data.data.id;
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
        _this.isLoading = false;
      });
    },
    chat_prev() {
      let _this = this;
      _this.isLoading = true;
      let cur_url = '/photo/chat_prev';
      if (this.chat_id == 0) {
        cur_url = '/photo/chat_last';
      }
      this.$axios({
        url: cur_url,
        data: JSON.stringify({
          id: _this.chat_id
        }),
        method: "POST"
      }).then(function (response) {
        console.log(response.data);
        _this.isLoading = false;
        if (response.data.code == 200) {
          _this.textarea2 = response.data.data.result;
          _this.chat_id = response.data.data.id;
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        console.log(error);
        _this.isLoading = false;
      });
    },
    chat_next() {
      let _this = this;
      _this.isLoading = true;
      let cur_url = '/photo/chat_next';
      if (this.chat_id == 0) {
        cur_url = '/photo/chat_last';
      }
      this.$axios({
        url: cur_url,
        data: JSON.stringify({
          id: _this.chat_id
        }),
        method: "POST"
      }).then(function (response) {
        _this.isLoading = false;
        console.log(response.data);
        if (response.data.code == 200) {
          _this.textarea2 = response.data.data.result;
          _this.chat_id = response.data.data.id;
        } else {
          ElMessage(response.data.message);
        }
      }).catch(function (error) {
        _this.isLoading = false;
        console.log(error);
      });
    },
    getImageUrl() {
      let index = Math.floor(Math.random() * this.yourImageUrls.length);
      return this.yourImageUrls[index];
    }
  }
};