export default {
  name: 'CustomLoading',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: 'your-image-url'
    }
  }
};